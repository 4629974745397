<template>
  <div class="my-3">
    <b-row class="justify-content-md-center">
      <b-col>
        <div class="align-items-center text-center justify-content-center">
          <p class="mb-5 font-weight-bold font-large-3">Verify Email Address</p>
          <p class="mb-2 font-large-2">Email Address Verified</p>
          <p class="font-large-1">Your Account has been verified</p>
            <b-link :to="{name:'page-change-password'}">
              <span class="font-large-1">&nbsp;Change password</span>
            </b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
